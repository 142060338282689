import { IEventAttendeeResponse, IEvent } from '@models/event.interface';
import api from '../api';

export const getEventAttendee = async (
  userId: string,
): Promise<Array<{ event: IEvent }>> => {
  const resp = await api.get<IEventAttendeeResponse>(
    `/event-attendee?filter[attendee]=${userId}`,
  );

  return resp.data.rows;
};

export const getEventId = async (eventId: string): Promise<any> => {
  const resp = await api.get(`/event/${eventId}`);

  return resp.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEventSpeakersAndEhxibitors = async (slug: string) => {
  const resp = await api.get(`/event/slug/${slug}/speakers-and-exhibitors`);

  return resp.data;
};

export const getEventSlug = async (eventSlug: string): Promise<IEvent> => {
  const event = await api.get<IEvent>(`/event/slug/${eventSlug}`);
  return event.data[0];
};

export const getEventSpeakers = async (
  eventId: string,
): Promise<Array<any>> => {
  try {
    const resp = await api
      .get(`/event-speaker?filter[event]=${eventId}`)
      .catch(error => {
        throw error;
      });
    return resp.data.rows;
  } catch (error) {
    return [];
  }
};
